import React from "react"
import Layout from "../../Layout/Layout"
import styles from "../../../styles/digitalKit.module.scss"
import WhiteSection from "../../whiteSection/whiteSection"
import tt from "../../../helpers/translation"
import buildResponsiveImages from "../../../helpers/buildResponsiveImages"
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage"
import { SeeMoreButton } from "../../seeMoreButton/seeMoreButton"

const DigitalKitPage = ({ data, lang, seoData, urls }) => {
  const digitalKitImagesDesktop = data.digitalKitImagesDesktop.nodes.map(
    node => node.childImageSharp
  )
  const digitalKitImagesMobile = data.digitalKitImagesMobile.nodes.map(
    node => node.childImageSharp
  )

  const [kitImage] = buildResponsiveImages(
    digitalKitImagesDesktop,
    digitalKitImagesMobile
  ).map((item, index) => {
    return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
  })

  return (
    <Layout
      leftContactUs
      lan={lang}
      location="digitalKit"
      contrast={true}
      seoData={seoData}
      urls={urls}
    >
      <WhiteSection
        first
        firstTitle={tt("Digital toolkit", lang)}
        subtitle={tt("Programa Kit Digital", lang)}
      />
      <div className={styles.content_container_first_block}>
        <h2>{tt("¿QUÉ ES EL PROGRAMA Kit Digital?", lang)}</h2>
        <div>
          <p className={styles.description}>
            {tt(
              "El Kit Digital es una iniciativa del Ministerio de Asuntos Económicos y Transformación Digital del Gobierno de España que tiene como objetivo proporcionar el acceso a una serie de ayudas económicas orientadas a la contratación de servicios digitales que impulsen la transición digital de las empresas españolas.",
              lang
            )}
          </p>
          <p className={styles.description}>
            {tt(
              "La forma de canalizar estas ayudas es mediante la contratación de los agentes digitales homologados dentro del programa Kit Digital. ",
              lang
            )}
          </p>
          <p className={styles.description}>
            {tt(
              "Desde Interficie, como agente digital homologado, ofrecemos el ciclo completo del proceso de transformación digital: desde la consultoría de procesos y equipo, hasta el diseño y desarrollo de soluciones de software que ayudarán a mejorar la eficiencia y eficacia de tu organización. Además podemos acompañarte en las gestiones para tramitar la subvención del Kit Digital.",
              lang
            )}
          </p>
        </div>
        <h2 className={styles.margin_top}>
          {tt("¿QUIÉN PUEDE BENEFICIARSE DEL Kit Digital?", lang)}
        </h2>
        <div>
          <p className={styles.description}>
            {tt(
              "Las pymes, microempresas o trabajadores autónomos con domicilio fiscal ubicado en territorio español y que cumplan con los siguientes requisitos:",
              lang
            )}
          </p>
          <ul>
            <li className={styles.description}>
              {tt("Ser una pequeña empresa, microempresa o autónomo.", lang)}
            </li>
            <li className={styles.description}>
              {tt(
                "Cumplir los límites financieros y efectivos que definen las categorías de empresas.",
                lang
              )}
            </li>
            <li className={styles.description}>
              {tt("No tener consideración de empresa en crisis.", lang)}
            </li>
            <li className={styles.description}>
              {tt(
                "Estar al corriente de las obligaciones tributarias y frente a la Seguridad Social.",
                lang
              )}
            </li>
            <li className={styles.description}>
              {tt(
                "No estar sujeta a una orden de recuperación pendiente de la Comisión Europea que haya declarado una ayuda ilegal e incompatible con el mercado común.",
                lang
              )}
            </li>
            <li className={styles.description}>
              {tt(
                "No incurrir en ninguna de las prohibiciones previstas en el artículo 13.2 de la Ley 38/2003, de 17 de noviembre, General de Subvenciones.",
                lang
              )}
            </li>
            <li className={styles.description}>
              {tt(
                "No superar el límite de ayudas minimis (de pequeña cuantía).",
                lang
              )}
            </li>
          </ul>
        </div>
        <h2 className={styles.margin_top}>
          {tt("¿CÓMO SOLICITAR EL BONO DEL Kit Digital?", lang)}
        </h2>
        <ol>
          <li className={styles.description}>
            {tt(
              "En primer lugar debes registrarte en  www.acelerapyme.es, allí encontrarás un Test de Diagnóstico Digital que te ayudaremos a realizar.",
              lang
            )}
          </li>
          <li className={styles.description}>
            {tt(
              "Una vez realizado el test, te asesoraremos para elegir las soluciones que mejor se adaptan a las necesidades que tiene tu negocio.",
              lang
            )}
          </li>
          <li className={styles.description}>
            {tt(
              "Nos encargaremos de realizar las gestiones necesarias para solicitar el bono del Kit Digital para tu empresa.",
              lang
            )}
          </li>
          <li className={styles.description}>
            {tt(
              "Como agentes digitalizadores, suscribiremos con tu empresa el acuerdo de prestación del servicio.",
              lang
            )}
          </li>
        </ol>
        <div className={styles.section3_footer}>
          <div className={styles.image_container}>{kitImage}</div>
        </div>
      </div>
      <hr />
      <div className={styles.content_container_second_block}>
        <h2>{tt("¿QUÉ SOLUCIONES DIGITALES OFRECEMOS?", lang)}</h2>
        <p className={styles.centered}>
          {tt(
            "Desde Interficie podemos ayudarte en los siguientes proyectos reconocidos dentro del programa de Kit Digital.",
            lang
          )}
        </p>
        <h3>{tt("Proyectos para la gestión de clientes", lang)}</h3>
        <p>
          {tt(
            "Desarrollo e implementación de una solución que permita digitalizar y optimizar la gestión de las relaciones comerciales con los clientes. Las principales funcionalidades de la solución serán:",
            lang
          )}
        </p>
        <ul>
          <li>
            {tt(
              "Análisis de los requisitos que deberá cubrir la aplicación.",
              lang
            )}
          </li>
          <li>{tt("Módulo para la gestión de clientes.", lang)}</li>
          <li>
            {tt(
              "Módulos para la gestión de clientes potenciales (Leads).",
              lang
            )}
          </li>
          <li>{tt("Módulo para la gestión de oportunidades.", lang)}</li>
          <li>
            {tt(
              "Módulo para la gestión de acciones o tareas comerciales.",
              lang
            )}
          </li>
          <li>
            {tt(
              "Módulo de reporting, planificación y seguimiento comercial.",
              lang
            )}
          </li>
          <li>{tt("Sistema de alertas.", lang)}</li>
          <li>{tt("Módulo para la gestión documental.", lang)}</li>
          <li>{tt("Diseño Responsive.", lang)}</li>
          <li>{tt("Integración con otras plataformas.", lang)}</li>
        </ul>
        <p>
          {tt(
            "Importe de la ayuda para empresas entre 10 y menos de 50 empleados: ",
            lang
          )}
          <strong>4000 €</strong>
        </p>
        <SeeMoreButton
          text={tt("Más información", lang)}
          contrast={true}
          sendTo={tt("/digital-kit/proyectos-gestion-clientes/", lang)}
        />
        <hr />
        <h3>{tt("Business Intelligence y Analítica", lang)}</h3>
        <p>
          {tt(
            "Implantación de herramientas digitales para la explotación de los datos de la empresa que permitan mejorar el proceso de toma de decisiones empresariales. Las principales funcionalidades de la solución las describimos a continuación:"
          )}
        </p>
        <p>
          <strong>
            {tt("Análisis de los requisitos que deberá cubrir la aplicación.")}
          </strong>{" "}
          {tt(
            "Este proceso es esencial para la posterior implantación y despliegue de la solución de digitalización que proporcionará, como mínimo, las funcionalidades y servicios que se detallan a continuación:"
          )}
        </p>
        <ul>
          <li>{tt("Integración de datos con otras bases de datos.", lang)}</li>
          <li>{tt("Almacenamiento de datos.", lang)}</li>
          <li>
            {tt("Creación de paneles de datos estructurados y visuales.", lang)}
          </li>
          <li>{tt("Exportación de datos", lang)}</li>
        </ul>
        <p>
          {tt(
            "Importe de la ayuda para empresas entre 10 y menos de 50 empleados: ",
            lang
          )}
          <strong>4000 €</strong>
        </p>
        <SeeMoreButton
          text={tt("Más información", lang)}
          contrast={true}
          sendTo={tt("/digital-kit/business-intelligence-analitica/", lang)}
        />
        <hr />
        <h3>{tt("Proyectos para la gestión de procesos", lang)}</h3>
        <p>
          {tt(
            "Implantación de herramientas digitales para digitalizar y/o automatizar procesos de negocio relacionados con los aspectos operativos o productivos de tu empresa. Las principales funcionalidades de la solución las describimos a continuación:"
          )}
        </p>
        <p>
          <strong>
            {tt("Análisis de los requisitos que deberá cubrir la aplicación.")}
          </strong>{" "}
          {tt(
            "Este proceso es esencial para la posterior implantación y despliegue de la solución de digitalización que proporcionará, como mínimo, las funcionalidades y servicios que se detallan a continuación:"
          )}
        </p>
        <ul>
          <li>
            {tt(
              "Digitalización y/o automatización de procesos y flujos de trabajo.",
              lang
            )}
          </li>
          <li>{tt("Contabilidad/finanzas.", lang)}</li>
          <li>{tt("Facturación.", lang)}</li>
          <li>{tt("Proyectos.", lang)}</li>
          <li>{tt("Inventario.", lang)}</li>
          <li>{tt("Integración con diversas plataformas.", lang)}</li>
          <li>{tt("Actualizable.", lang)}</li>
          <li>{tt("Escalable.", lang)}</li>
        </ul>
        <p>
          {tt(
            "Importe de la ayuda para empresas entre 10 y menos de 50 empleados: ",
            lang
          )}
          <strong>6000 €</strong>
        </p>
        <SeeMoreButton
          text={tt("Más información", lang)}
          contrast={true}
          sendTo={tt("/digital-kit/proyectos-gestion-procesos/", lang)}
        />
        <hr />
        <h3>{tt("Comercio Electrónico", lang)}</h3>
        <p>
          {tt(
            "Creación de una tienda online de compraventa de productos y/o servicios que utilice medios digitales para su intercambio."
          )}
        </p>
        <p>
          <strong>
            {tt("Análisis de los requisitos que deberá cubrir la aplicación.")}
          </strong>{" "}
          {tt(
            "Este proceso es esencial para la posterior implantación y despliegue de la solución de digitalización que proporcionará, como mínimo, las funcionalidades y servicios que se detallan a continuación:"
          )}
        </p>
        <ul>
          <li>
            {tt(
              "Creación de la tienda online o E-Commerce y alta del catálogo de productos.",
              lang
            )}
          </li>
          <li>{tt("Métodos de pago.", lang)}</li>
          <li>{tt("Diseño Responsive.", lang)}</li>
          <li>{tt("Accesibilidad.", lang)}</li>
          <li>{tt("Posicionamiento básico en internet.", lang)}</li>
          <li>
            {tt("Optimización de la presencia en buscadores (SEO).", lang)}
          </li>
          <li>{tt("Autogestionable.", lang)}</li>
          <li>{tt("Formas de envío.", lang)}</li>
        </ul>
        <p>
          {tt(
            "Importe de la ayuda para empresas entre 10 y menos de 50 empleados: ",
            lang
          )}
          <strong>2000 €</strong>
        </p>
        <SeeMoreButton
          text={tt("Más información", lang)}
          contrast={true}
          sendTo={tt("/digital-kit/comercio-electronico/", lang)}
        />
        <hr />
        <h3>{tt("Sitio web y presencia en internet", lang)}</h3>
        <p>
          {tt(
            "Expansión de la presencia en internet de la pyme mediante la creación de una página web y/o la prestación de servicios que proporcionen posicionamiento básico en internet."
          )}
        </p>
        <p>
          <strong>
            {tt("Análisis de los requisitos que deberá cubrir la aplicación.")}
          </strong>{" "}
          {tt(
            "Este proceso es esencial para la posterior implantación y despliegue de la solución de digitalización que proporcionará, como mínimo, las funcionalidades y servicios que se detallan a continuación:"
          )}
        </p>
        <ul>
          <li>{tt("Alta de dominio.", lang)}</li>
          <li>{tt("Hosting.", lang)}</li>
          <li>{tt("Diseño de la página web.", lang)}</li>
          <li>{tt("Web responsive.", lang)}</li>
          <li>{tt("Accesibilidad.", lang)}</li>
          <li>{tt("Autogestionable.", lang)}</li>
          <li>{tt("Posicionamiento básico en internet.", lang)}</li>
          <li>
            {tt(
              "Optimización de la presencia en buscadores (SEO básico).",
              lang
            )}
          </li>
        </ul>
        <p>
          {tt(
            "Importe de la ayuda para empresas entre 10 y menos de 50 empleados: ",
            lang
          )}
          <strong>2000 €</strong>
        </p>
        <SeeMoreButton
          text={tt("Más información", lang)}
          contrast={true}
          sendTo={tt("/digital-kit/sitio-web-presencia/", lang)}
        />
      </div>
      <div className={styles.blue_section_container}>
        <div className={styles.first_section}>
          <div className={styles.blue_text_container}>
            <p>{tt("¿Quieres conocer nuestro trabajo?", lang)}</p>
            <SeeMoreButton
              text={tt("What we do", lang)}
              sendTo={tt("/what-we-do/", lang)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DigitalKitPage
