const buildResponsiveImages = (desktopImages, mobileImages) => {
  if (desktopImages.length !== mobileImages.length) {
    throw new Error("Lengths must match!")
  }

  const output = []
  for (let i = 0; i < desktopImages.length; i++) {
    output.push({
      ...desktopImages[i],
      fluid: [
        { ...mobileImages[i].fluid },
        { ...desktopImages[i].fluid, media: `(min-width: 525px)` },
      ],
    })
  }
  return output
}

export default buildResponsiveImages
